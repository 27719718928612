import { useMemo } from "react";
import PropTypes from "prop-types";

const Property1Default = ({
  className = "",
  exploreOurCulture,
  property1DefaultWidth,
  property1DefaultHeight,
  property1DefaultPosition,
  property1DefaultTop,
  property1DefaultLeft,
}) => {
  const property1DefaultStyle = useMemo(() => {
    return {
      width: property1DefaultWidth,
      height: property1DefaultHeight,
      position: property1DefaultPosition,
      top: property1DefaultTop,
      left: property1DefaultLeft,
    };
  }, [
    property1DefaultWidth,
    property1DefaultHeight,
    property1DefaultPosition,
    property1DefaultTop,
    property1DefaultLeft,
  ]);

  return (
    <div
      className={`w-[373px] max-w-full h-[100px] text-center text-7xl text-white font-benzin-regular ${className}`}
      style={property1DefaultStyle}
    >
      <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-41xl bg-dark "/>
      <div className="absolute top-[10%] left-[8%] leading-[100%]">
        {exploreOurCulture}
      </div>
    </div>
  );
};

Property1Default.propTypes = {
  className: PropTypes.string,
  exploreOurCulture: PropTypes.string,

  /** Style props */
  property1DefaultWidth: PropTypes.any,
  property1DefaultHeight: PropTypes.any,
  property1DefaultPosition: PropTypes.any,
  property1DefaultTop: PropTypes.any,
  property1DefaultLeft: PropTypes.any,
};

export default Property1Default;

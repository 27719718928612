import PropTypes from "prop-types";

const SoftwareDevelopmentSection = ({ className = "" }) => {
  return (
    <div
      className={`w-[1783.1px] relative h-[744px] shrink-0 text-left text-11xl text-white font-benzin-medium ${className}`}
    >
      <div className="absolute top-[0px] left-[0px] w-[1783.1px] h-[744px]">
        <img
          className="absolute top-[0px] left-[0px] rounded-11xl w-[1783.1px] h-[744px] object-cover"
          alt=""
          src="/businesswomanholdingcupcoffeewhilediscussingwithmultiethnicteamworksolvingmanagementprojectusinggraphspaperworkdiversecoworkersworkingmeetingroomlatenight-12@2x.png"
        />
        <div className="absolute top-[744px] left-[1783.1px] rounded-11xl [background:linear-gradient(90deg,_#000,_rgba(115,_115,_115,_0))] w-[1783.1px] h-[744px] [transform:_rotate(-180deg)] [transform-origin:0_0]" />
        <div className="absolute top-[50px] left-[1313.8px] leading-[108%] inline-block w-[426px] h-[33px]">
          Software Development
        </div>
      </div>
      <div className="absolute w-[calc(100%_-_320.1px)] top-[435px] left-[290.8px] text-46xl leading-[108%] inline-block">
        "Pioneering the Future with Next-Gen Software Solutions."
      </div>
      <div className="absolute top-[645px] left-[1459.8px] w-[218px] h-[50px] text-3xl text-dark font-benzin-regular">
        <div className="absolute top-[0px] left-[0px] rounded-mini bg-white w-[218px] h-[50px]" />
        <div className="absolute top-[15px] left-[19px] leading-[108%]">
          View more
        </div>
        <img
          className="absolute h-[30%] w-[4.13%] top-[38%] right-[9.17%] bottom-[32%] left-[86.7%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/vector.svg"
        />
      </div>
    </div>
  );
};

SoftwareDevelopmentSection.propTypes = {
  className: PropTypes.string,
};

export default SoftwareDevelopmentSection;

import GroupComponent1 from "./GroupComponent1";
import SoftwareDevelopmentSection from "./SoftwareDevelopmentSection";
import PropTypes from "prop-types";

const FrameComponent = ({ className = "" }) => {
  return (
    <div
      className={`absolute top-[156px] left-[68px] w-[1783px] overflow-x-auto flex flex-row items-start justify-start gap-[226px] text-left text-11xl text-white font-benzin-regular ${className}`}
    >
      <GroupComponent1 />
      <div className="w-[1783.1px] relative h-[744px] shrink-0">
        <img
          className="absolute top-[0px] left-[0px] rounded-11xl w-[1783.1px] h-[744px] object-cover"
          alt=""
          src="/businesswomanholdingcupcoffeewhilediscussingwithmultiethnicteamworksolvingmanagementprojectusinggraphspaperworkdiversecoworkersworkingmeetingroomlatenight-11@2x.png"
        />
        <div className="absolute top-[744px] left-[1783.1px] rounded-11xl [background:linear-gradient(90deg,_#000,_rgba(115,_115,_115,_0))] w-[1783.1px] h-[744px] [transform:_rotate(-180deg)] [transform-origin:0_0]" />
        <div className="absolute top-[50px] left-[1379.9px] leading-[108%] inline-block w-[351px] h-[33px]">
          Staff Augmentation
        </div>
        <div className="absolute w-[calc(100%_-_255.1px)] top-[435px] left-[204.9px] text-46xl leading-[108%] font-benzin-medium inline-block">
          "We source talent that not only fits your vision but also fuels your
          enduring achievements."
        </div>
        <div className="absolute top-[645px] left-[1446.9px] w-[218px] h-[50px] text-3xl text-dark">
          <div className="absolute top-[0px] left-[0px] rounded-mini bg-white w-[218px] h-[50px]" />
          <div className="absolute top-[15px] left-[19px] leading-[108%]">
            View more
          </div>
          <img
            className="absolute h-[30%] w-[4.13%] top-[38%] right-[9.17%] bottom-[32%] left-[86.7%] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/vector.svg"
          />
        </div>
      </div>
      <SoftwareDevelopmentSection />
      <div className="w-[1783.1px] relative h-[744px] shrink-0">
        <div className="absolute top-[0px] left-[0px] w-[1783.1px] h-[744px]">
          <img
            className="absolute top-[0px] left-[0px] rounded-11xl w-[1783.1px] h-[744px] object-cover"
            alt=""
            src="/businesswomanholdingcupcoffeewhilediscussingwithmultiethnicteamworksolvingmanagementprojectusinggraphspaperworkdiversecoworkersworkingmeetingroomlatenight-13@2x.png"
          />
          <div className="absolute top-[744px] left-[1783.1px] rounded-11xl [background:linear-gradient(90deg,_#000,_rgba(115,_115,_115,_0))] w-[1783.1px] h-[744px] [transform:_rotate(-180deg)] [transform-origin:0_0]" />
          <div className="absolute top-[50px] left-[1356.7px] leading-[108%] inline-block w-96 h-[33px]">
            Product Development
          </div>
        </div>
        <div className="absolute w-[calc(100%_-_615.1px)] top-[435px] left-[579px] text-46xl leading-[108%] font-benzin-medium inline-block">
          "Designing the Future: Where Ideas Become Products."
        </div>
        <div className="absolute top-[645px] left-[1489.7px] w-[218px] h-[50px] text-3xl text-dark">
          <div className="absolute top-[0px] left-[0px] rounded-mini bg-white w-[218px] h-[50px]" />
          <div className="absolute top-[15px] left-[19px] leading-[108%]">
            View more
          </div>
          <img
            className="absolute h-[30%] w-[4.13%] top-[38%] right-[9.17%] bottom-[32%] left-[86.7%] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/vector.svg"
          />
        </div>
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;

import { useMemo } from "react";
import PropTypes from "prop-types";

const GroupComponent = ({
  className = "",
  productDevelopment,
  weTransformIdeasIntoMarke,
  propLeft,
  propTop,
}) => {
  const groupDivStyle = useMemo(() => {
    return {
      left: propLeft,
      top: propTop,
    };
  }, [propLeft, propTop]);

  return (
    <div
      className={`absolute top-[1211px] left-[179px] w-[758px] h-[390px] text-left text-13xl text-dark font-benzin-medium ${className}`}
      style={groupDivStyle}
    >
      <div className="absolute top-[0px] left-[0px] w-[758px] h-[390px]">
        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-11xl bg-white hover:shadow-white shadow-xl box-border border-[1px] border-solid border-dark" />
      </div>
      <div className="absolute top-[36px] left-[calc(50%_-_350px)] leading-[108%]">
        {productDevelopment}
      </div>
      <div className="absolute top-[115px] left-[calc(50%_-_339px)] text-9xl leading-[120%] font-benzin-regular text-gray inline-block w-[675px]">
        {weTransformIdeasIntoMarke}
      </div>
    </div>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
  productDevelopment: PropTypes.string,
  weTransformIdeasIntoMarke: PropTypes.string,

  /** Style props */
  propLeft: PropTypes.any,
  propTop: PropTypes.any,
};

export default GroupComponent;

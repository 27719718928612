import FrameComponent from "../components/FrameComponent";
import GroupComponent from "../components/GroupComponent";
import Property1Default from "../components/Property1Default";

const LandingPage = () => {
  return (
    <div className="w-full relative bg-white h-[7645px] overflow-hidden text-left text-7xl text-gray font-benzin-regular">
      <div className="absolute top-[59px] left-[69px] w-[608px] h-6 text-3xl">
        <div className="absolute top-[0px] left-[0px] leading-[108%] inline-block w-[126px] text-gray hover:text-black">
          Home
        </div>
        <div className="absolute top-[0px] left-[215px] leading-[108%] inline-block w-[151px]  text-gray hover:text-black">
          About Us
        </div>
        <div className="absolute top-[0px] left-[455px] leading-[108%] inline-block w-[153px] text-gray hover:text-black">
          Industries
        </div>
      </div>
      <div className="absolute top-[59px] left-[1280px] w-[572px] h-6 text-3xl">
        <div className="absolute top-[0px] left-[0px] leading-[108%] inline-block w-[146px] text-gray hover:text-black">
          Services
        </div>
        <div className="absolute top-[0px] left-[235px] leading-[108%] inline-block w-[121px] text-gray hover:text-black">
          Career
        </div>
        <div className="absolute top-[0px] left-[445px] leading-[108%] inline-block w-[127px] text-gray hover:text-black">
          Contact
        </div>
      </div>
      <div className="absolute h-[calc(100%_-_7574px)] top-[51px] bottom-[7523px] left-[calc(50%_-_241px)] w-[482px] text-26xl text-dark font-benzin-bold">
        <div className="absolute h-[calc(100%_-_22px)] top-[0px] left-[calc(50%_-_241px)] leading-[108%] inline-block w-[482px]">
          CYBERNETIQUE
        </div>
        <div className="absolute top-[49px] left-[161px] text-xl leading-[108%] font-benzin-regular text-gray hover:text-orange-500 inline-block w-[321px]">
          A Big Business Start Small
        </div>
      </div>
      <FrameComponent />
      <div className="absolute top-[1019px] left-[calc(50%_-_179px)] text-26xl leading-[108%] font-benzin-semibold text-dark">
        Our Services
      </div>
      <div className="absolute top-[2708px] left-[calc(50%_-_253px)] text-26xl leading-[108%] font-benzin-semibold text-dark">
        Technology Stack
      </div>
      <div className="absolute top-[4254px] left-[calc(50%_-_203px)] text-26xl leading-[108%] font-benzin-semibold text-dark">
        Latest insights
      </div>
      <div className="absolute top-[3131px] left-[calc(50%_-_281px)] text-26xl leading-[108%] font-benzin-semibold text-dark text-center">
        <p className="m-0">{`Our Culture Shapes `}</p>
        <p className="m-0">Everything We Do</p>
      </div>
      <div className="absolute top-[927px] left-[868px] w-[184px] h-1">
        <div className="absolute top-[0px] left-[0px] bg-dark w-10 h-1" />
        <div className="absolute top-[0px] left-[48px] bg-dark w-10 h-1 opacity-[0.2]" />
        <div className="absolute top-[0px] left-[96px] bg-dark w-10 h-1 opacity-[0.2]" />
        <div className="absolute top-[0px] left-[144px] bg-dark w-10 h-1 opacity-[0.2]" />
      </div>
      <div className="absolute top-[1107px] left-[0px] bg-dark w-[1920px] h-[1482px]" />
      <GroupComponent
        productDevelopment="Product Development"
        weTransformIdeasIntoMarke="We transform ideas into market-ready products using innovative design, agile development, and rigorous testing. Ensure your product's success with our expert support and strategic market readiness."
      />
      <GroupComponent
        productDevelopment="Staff Augmentation"
        weTransformIdeasIntoMarke="Empower your business with our tailored staffing services. We source and place professionals who drive success and innovation."
        propLeft="175px"
        propTop="1651px"
      />
      <GroupComponent
        productDevelopment="Software Development"
        weTransformIdeasIntoMarke="We turn concepts into high-quality software using cutting-edge technology, agile methodologies, and thorough testing. Ensure your software's success with our expert development and continuous support."
        propLeft="987px"
        propTop="1211px"
      />
      <GroupComponent
        productDevelopment="Business Consulting"
        weTransformIdeasIntoMarke={`"Our Business Consulting drives growth through strategic insights and innovative solutions. Partner with us to optimize operations and maximize profitability."`}
        propLeft="987px"
        propTop="1651px"
      />
      <GroupComponent
        productDevelopment="Application Development"
        weTransformIdeasIntoMarke={`"We specialize in crafting innovative applications that meet modern business needs. Our team uses cutting-edge technologies to deliver high-quality, scalable solutions from concept to deployment."`}
        propLeft="987px"
        propTop="2091px"
      />
      <GroupComponent
        productDevelopment="Trainings"
        weTransformIdeasIntoMarke="Advance your career with our industry-leading training. Focused, impactful education to keep you ahead of the curve."
        propLeft="175px"
        propTop="2091px"
      />
      <div className="absolute top-[2787px] left-[57px] w-[336px] scroll-smooth animate-loop-scroll flex flex-row items-center justify-start gap-[119px]">
        <img
          className="w-[300px] relative h-24 shrink-0 object-cover"
          alt=""
          src="/microsoftlogo-1@2x.png"
        />
        <img
          className="w-[196px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/saplogo768x432-1@2x.png"
        />
        <img
          className="w-[348px] relative h-[98px] shrink-0 object-cover"
          alt=""
          src="/oraclelogo1768x432-1@2x.png"
        />
        <img
          className="w-[301px] relative h-[81px] shrink-0 object-cover"
          alt=""
          src="/images-2-1@2x.png"
        />
        <img
          className="w-[239px] relative h-[167px] shrink-0 object-cover"
          alt=""
          src="/salesforcecom-logosvg-1@2x.png"
        />
        <img
          className="w-[304px] relative h-[99.6px] shrink-0 object-cover"
          alt=""
          src="/favpng-machinelearningdeeplearningartificialintelligencesupervisedlearningsupportvectormachine-1@2x.png"
        />
        <img
          className="w-[382px] relative h-[135px] shrink-0 object-cover"
          alt=""
          src="/react-img-1@2x.png"
        />
        <img
          className="w-[193px] relative h-44 shrink-0 object-cover"
          alt=""
          src="/py-logo-1@2x.png"
        />
        <img
          className="w-[134px] relative h-[209px] shrink-0 object-cover"
          alt=""
          src="/javalogo-1@2x.png"
        />
        <img
          className="w-[193px] relative h-[193px] shrink-0 object-cover"
          alt=""
          src="/mongodb-original-wordmark-logo-icon-146425-1@2x.png"
        />
        <img
          className="w-[319px] relative h-[92px] shrink-0 object-cover"
          alt=""
          src="/microsoft-azure-logosvg-1@2x.png"
        />
        <img
          className="w-80 relative h-[158px] shrink-0 object-cover"
          alt=""
          src="/searchdataanalyticslogodesigngrowtharrowlogodesignfordatafinanceinvestmenticondesignvector-1@2x.png"
        />
        <img
          className="w-[156px] relative h-[93px] shrink-0 object-cover"
          alt=""
          src="/amazon-web-services-logosvg-1@2x.png"
        />
        <img
          className="w-48 relative h-48 shrink-0 object-cover"
          alt=""
          src="/pngtreecyber-security-system-technology-landing-14868800@2x.png"
        />
        <img
          className="w-[314px] relative h-[105px] shrink-0 object-cover"
          alt=""
          src="/1280pxmagento-logosvg@2x.png"
        />
      </div>
      <div className="absolute top-[2745px] left-[1258px] w-[663px] h-[291px]">
        <div className="absolute top-[0px] left-[0px] bg-white w-[663px] h-[291px]" />
        <div className="absolute top-[92px] left-[49px] leading-[120%] inline-block w-[566px]">
          <span>"</span>
          <span className="font-benzin-medium">Rising to the Top</span>
          <span>
            : Advancing from Basics to Expertise in Niche Skills and
            Technologies."
          </span>
        </div>
      </div>
      <div className="absolute top-[4366px] left-[0px] bg-gray1-200 w-[1922px] h-[915px]" />
      <div className="absolute top-[3259px] left-[calc(50%_-_719px)] leading-[120%] text-center inline-block w-[1438px]">
        "Our people, clients, and consultants are our top priority. We're driven
        by a culture of purpose, shared values, and growth. Discover who we are
        and experience our unique approach."
      </div>
      <Property1Default
        exploreOurCulture="Explore Our Culture"
        property1DefaultWidth="350px"
        property1DefaultHeight="86px"
        property1DefaultPosition="absolute"
        property1DefaultTop="3360px"
        property1DefaultLeft="calc(50% - 161px)"
      />
      <Property1Default
        exploreOurCulture="Read Now"
        property1DefaultWidth="216px"
        property1DefaultHeight="41px"
        property1DefaultPosition="absolute"
        property1DefaultTop="4658px"
        property1DefaultLeft="calc(50% + 139px)"
      />
      <Property1Default
        exploreOurCulture="Read More reviews"
        property1DefaultWidth="256px"
        property1DefaultHeight="56px"
        property1DefaultPosition="absolute"
        property1DefaultTop="6771px"
        property1DefaultLeft="calc(50% + 98px)"
      />
      <Property1Default
        exploreOurCulture="Visit Now"
        property1DefaultWidth="216px"
        property1DefaultHeight="41px"
        property1DefaultPosition="absolute"
        property1DefaultTop="5195px"
        property1DefaultLeft="calc(50% - 843px)"
      />
      <img
        className="absolute top-[3485px] left-[-65px] w-[2048px] h-[638px] object-cover"
        alt=""
        src="/homepagescreenshot2048x638-1@2x.png"
      />
      <img
        className="absolute top-[4370px] left-[-18px] rounded-t-none rounded-br-253xl rounded-bl-none w-[989px] h-[493px] object-cover"
        alt=""
        src="/htmlcsscollageconceptwithperson@2x.png"
      />
      <img
        className="absolute top-[4788px] left-[959px] rounded-tl-253xl rounded-tr-none rounded-b-none w-[989px] h-[493px] object-cover"
        alt=""
        src="/htmlcsscollageconceptwithperson1@2x.png"
      />
      <div className="absolute top-[4534px] left-[1094px] leading-[108%] font-benzin-medium text-mediumslateblue inline-block w-[720px]">
        Becoming a Digital Transformation Leader: What Does It Take?
      </div>
      <div className="absolute top-[5085px] left-[117px] leading-[108%] font-benzin-medium text-mediumslateblue inline-block w-[720px]">
        The Real Deal About Workforce Diversity and Inclusion
      </div>
      <div className="absolute top-[4606px] left-[1094px] text-sm leading-[108%] inline-block w-[666px]">
        Join the ranks of digital leaders taking all the right steps to make
        their transformation journey a success.
      </div>
      <div className="absolute top-[4484px] left-[1094px] text-3xl leading-[108%] font-benzin-medium text-dark inline-block w-[78px]">
        Story
      </div>
      <div className="absolute top-[5035px] left-[117px] text-3xl leading-[108%] font-benzin-medium text-dark inline-block w-[92px]">
        Article
      </div>
      <div className="absolute top-[5149px] left-[117px] text-sm leading-[108%] inline-block w-[666px]">
        Increasing retention is the only way we’ll move the needle in
        establishing long-term change in the tech industry.
      </div>
      <div className="absolute top-[5417px] left-[calc(50%_-_238px)] text-26xl leading-[108%] font-benzin-semibold text-dark">
        Trusted Partners
      </div>
      <div className="absolute top-[6377px] left-[calc(50%_+_98px)] leading-[108%] font-benzin-semibold text-dark">
        B.A Kumar
      </div>
      <div className="absolute top-[6618px] left-[calc(50%_+_98px)] leading-[108%] font-benzin-semibold text-dark">
        Sharath. C
      </div>
      <div className="absolute top-[6044px] left-[calc(50%_-_294px)] text-26xl leading-[108%] font-benzin-semibold text-dark">
        What our Clients say
      </div>
      <div className="absolute top-[5524px] left-[0px] bg-dark w-[1920px] h-96" />
      <div className="absolute top-[6989px] left-[0px] bg-dark w-[1920px] h-[656px]" />

      {/* Slider */}
      <div className="absolute top-[5631px] left-[705px] w-[428px] scroll-smooth animate-loop-scroll flex flex-row items-center justify-start gap-[86px]">
        <img
          className="w-[428px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/cap-logoremovebgpreview-1@2x.png"
        />
        <img
          className="w-[470.6px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/2142145556-deloittelogopngwhitetransparentpng--1-removebgpreview-1@2x.png"
        />
        <img
          className="w-[423px] relative h-[140.2px] shrink-0 object-cover"
          alt=""
          src="/1651655142-accenturelogoweihdpngdownloadremovebgpreview-1@2x.png"
        />
        <img
          className="w-[341.5px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/tech-mahindraremovebgpreview-1@2x.png"
        />
        <img
          className="w-[247.7px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/ibm-logoremovebgpreview-1@2x.png"
        />
        <img
          className="w-[318.1px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/infosysnyntaglinereversepng-1@2x.png"
        />
        <img
          className="w-[181px] relative h-[142px] shrink-0 object-cover"
          alt=""
          src="/wit-bigdcc466c6a-1@2x.png"
        />
        <img
          className="w-[175px] relative h-[123px] shrink-0 object-cover"
          alt=""
          src="/sonatasoftwarelogo2d77f170a2seeklogocom-1-1@2x.png"
        />
        <img
          className="w-[428px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/cap-logoremovebgpreview-2@2x.png"
        />
        <img
          className="w-[470.6px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/2142145556-deloittelogopngwhitetransparentpng--1-removebgpreview-2@2x.png"
        />
        <img
          className="w-[423px] relative h-[140.2px] shrink-0 object-cover"
          alt=""
          src="/1651655142-accenturelogoweihdpngdownloadremovebgpreview-2@2x.png"
        />
        <img
          className="w-[341.5px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/tech-mahindraremovebgpreview-2@2x.png"
        />
        <img
          className="w-[247.7px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/ibm-logoremovebgpreview-2@2x.png"
        />
        <img
          className="w-[318.1px] relative h-[110px] shrink-0 object-cover"
          alt=""
          src="/infosysnyntaglinereversepng-2@2x.png"
        />
        <img
          className="w-[181px] relative h-[142px] shrink-0 object-cover"
          alt=""
          src="/wit-bigdcc466c6a-2@2x.png"
        />
        <img
          className="w-[175px] relative h-[123px] shrink-0 object-cover"
          alt=""
          src="/sonatasoftwarelogo2d77f170a2seeklogocom-1-2@2x.png"
        />
      </div>

      <div className="absolute top-[5536px] left-[0px] rounded-xl bg-dark w-[655px] h-[359px]" />
      <div className="absolute top-[7220px] left-[calc(50%_-_913px)] leading-[108%] text-white hover:text-gray">
        Business
      </div>
      <div className="absolute top-[7199px] left-[calc(50%_-_443px)] leading-[108%] font-benzin-bold text-white">
        Services
      </div>
      <div className="absolute top-[7199px] left-[calc(50%_-_77px)] leading-[108%] font-benzin-bold text-white">
        Career
      </div>
      <div className="absolute top-[7199px] left-[calc(50%_+_256px)] leading-[108%] font-benzin-bold text-white">
        Contact
      </div>
      <div className="absolute top-[7192px] left-[calc(50%_+_606px)] leading-[108%] font-benzin-bold text-white">
        Get in touch
      </div>
      <div className="absolute top-[7281px] left-[calc(50%_-_913px)] leading-[108%] text-white hover:text-gray">
        Staffing
      </div>
      <div className="absolute top-[7342px] left-[calc(50%_-_913px)] leading-[108%] text-white hover:text-gray">
        Consulting
      </div>
      <div className="absolute top-[7403px] left-[calc(50%_-_913px)] leading-[108%] text-white hover:text-gray">
        Training
      </div>
      <div className="absolute top-[5624px] left-[26px] leading-[120%] inline-block w-[608px]">
        "Innovating Industry Standards Through Software Partnerships." -
        Together with our software partners, we're reshaping industries and
        setting new benchmarks for innovation and excellence.
      </div>
      <div className="absolute top-[6147px] left-[-95px] rounded-11xl bg-dark w-[1014px] h-[680px]" />
      <img
        className="absolute top-[6448px] left-[191px] rounded-11xl w-[299px] h-[334px] object-cover"
        alt=""
        src="/rectangle-19@2x.png"
      />
      <img
        className="absolute top-[6217px] left-[517px] rounded-11xl w-[485px] h-[542px] object-cover"
        alt=""
        src="/rectangle-23@2x.png"
      />
      <img
        className="absolute top-[6191px] left-[128px] rounded-11xl w-[426px] h-56 object-cover"
        alt=""
        src="/rectangle-21@2x.png"
      />
      <div className="absolute top-[6255px] left-[1058px] text-3xl leading-[108%] inline-block w-[847px]">
        “Working with Cybernetique has been an outstanding experience. Their
        team excels in various domains, including software development, staff
        augmentation, app development, business consulting, trainings, and
        product development.”
      </div>
      <div className="absolute top-[6520px] left-[1058px] text-3xl leading-[108%] inline-block w-[819px]">
        “Cybernetique is a reliable partner, delivering exceptional results
        across various services. We highly recommend them to any organization
        seeking comprehensive and expert solutions.”
      </div>
      <div className="absolute top-[6405px] left-[1058px] text-xl leading-[108%] text-dark inline-block w-[153px]">
        CTO, Infosys
      </div>
      <div className="absolute top-[6646px] left-[1058px] text-xl leading-[108%] text-dark inline-block w-[189px]">
        BoD, Capgemini
      </div>
      <div className="absolute h-[calc(100%_-_7574px)] top-[7061px] bottom-[513px] left-[calc(50%_-_925px)] w-[482px] text-26xl text-white font-benzin-bold">
        <div className="absolute h-[calc(100%_-_22px)] top-[0px] left-[calc(50%_-_241px)] leading-[108%] inline-block w-[482px]">
          CYBERNETIQUE
        </div>
        <div className="absolute top-[49px] left-[161px] text-xl leading-[108%] font-benzin-regular text-gray hover:text-orange-500 inline-block w-[321px]">
          A Big Business Start Small
        </div>
      </div>
      <div className="absolute top-[7255px] left-[517px] w-[260px] h-[22px] text-lg text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          Product Development
        </div>
      </div>
      <div className="absolute top-[7255px] left-[883px] w-[260px] h-[22px] text-lg  text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          India
        </div>
      </div>
      <div className="absolute top-[7305px] left-[883px] w-[260px] h-[22px] text-lg  text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          U.S.A
        </div>
      </div>
      <div className="absolute top-[7355px] left-[883px] w-[260px] h-[22px] text-lg  text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          Singapore
        </div>
      </div>
      <div className="absolute top-[7580px] left-[47px] w-[295px] h-[22px] text-lg">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          <span>{`All rights reserved © `}</span>
          <span className="text-base">2024</span>
        </div>
      </div>
      <div className="absolute top-[7255px] left-[1216px] w-[260px] h-[22px] text-lg  text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          For Services
        </div>
      </div>
      <div className="absolute top-[7255px] left-[1602px] w-[260px] h-[22px] text-lg">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          +91 9640070809
        </div>
      </div>
      <div className="absolute w-[10.63%] top-[95.46%] left-[83.44%] text-lg leading-[108%] inline-block">
        hr@cybernetique.in
      </div>
      <div className="absolute w-[15.21%] top-[95.98%] left-[83.44%] text-lg leading-[108%] inline-block">
        <p className="m-0">{`Plot no : 205 `}</p>
        <p className="m-0">{`Flat no : 103 Sai Enclave `}</p>
        <p className="m-0">{`Anjaneya nagar Moosapet `}</p>
        <p className="m-0">Hyderabad 500018</p>
      </div>
      <div className="absolute top-[7305px] left-[1216px] w-[260px] h-[22px] text-lg text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          For Jobseeker
        </div>
      </div>
      <div className="absolute top-[7305px] left-[517px] w-[260px] h-[22px] text-lg text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          Software Development
        </div>
      </div>
      <div className="absolute top-[7355px] left-[517px] w-[270px] h-[22px] text-lg text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          Application Development
        </div>
      </div>
      <div className="absolute top-[7405px] left-[517px] w-[270px] h-[22px] text-lg text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          Staff Augmentation
        </div>
      </div>
      <div className="absolute top-[7455px] left-[517px] w-[270px] h-[22px] text-lg text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          Business Consulting
        </div>
      </div>
      <div className="absolute top-[7505px] left-[517px] w-[270px] h-[22px] text-lg text-gray hover:text-white">
        <div className="absolute w-full top-[0%] left-[0%] leading-[108%] inline-block">
          Training
        </div>
      </div>
      <img
        className="absolute h-[0.18%] w-[0.94%] top-[95.5%] right-[17.5%] bottom-[4.32%] left-[81.56%] max-w-full overflow-hidden max-h-full"
        alt=""
        src="/page1.svg"
      />
      <img
        className="absolute h-[0.19%] w-[0.78%] top-[94.9%] right-[17.66%] bottom-[4.91%] left-[81.56%] max-w-full overflow-hidden max-h-full"
        alt=""
        src="/page11.svg"
      />
      <img
        className="absolute h-[0.29%] w-[0.83%] top-[95.96%] right-[17.6%] bottom-[3.76%] left-[81.56%] max-w-full overflow-hidden max-h-full"
        alt=""
        src="/layer1.svg"
      />
    </div>
  );
};

export default LandingPage;
